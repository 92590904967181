import { IoNewspaper, IoPersonSharp } from 'react-icons/io5';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { IoIosSpeedometer } from 'react-icons/io';
import { BsPersonFill, BsListUl, BsGearFill } from 'react-icons/bs';

export const ROUTES = [
  {
    id: 1,
    name: 'Devices',
    label: undefined,
    icon: <IoIosSpeedometer />,
    pathname: '/admin',
  },
  // { id: 2, name: 'Journal', label: 'journal', icon: <IoNewspaper />, pathname: '/admin/journal' },
  { id: 3, name: 'Map', label: 'map', icon: <FaMapMarkedAlt />, pathname: '/admin/map' },
  { id: 4, name: 'Users', label: 'users', icon: <IoPersonSharp />, pathname: '/admin/users' },
];

export const TOPBAR_MENU = [
  { id: 1, label: 'Профиль', icon: <BsPersonFill />, pathname: '' },
  { id: 2, label: 'Настройки', icon: <BsGearFill />, pathname: '' },
  { id: 3, label: 'Журнал активности', icon: <BsListUl />, pathname: '' },
];

export const DEVICES_TABLE_KEYS = [
  // { id: 1, name: 'id' },
  { id: 1, show: true, name: 'sn', label: 'Серийный номер' },
  { id: 4, show: true, name: 'object_name', label: 'Название объекта' },
  { id: 5, show: true, name: 'region', label: 'Регион' },
  { id: 6, show: true, name: 'district', label: 'Район' },
  { id: 7, show: true, name: 'address', label: 'Адрес' },
  { id: 8, show: true, name: 'time_of_data', label: 'Время данных' },
  { id: 9, show: true, name: 'last_water_level', label: 'Уровень воды (м)' },
  { id: 10, show: true, name: 'last_water_conductivity', label: 'Электропроводность (мкСм/см)' },
  { id: 11, show: true, name: 'last_water_temperature', label: 'Температура воды (°C)' },
  { id: 12, show: true, name: 'installed_depth', label: 'Глубина установки (м)' },
  { id: 13, show: true, name: 'phone_number', label: 'Номер телефона' },
  { id: 14, show: true, name: 'sim_number', label: 'Номер SIM-карты' },
  { id: 15, show: true, name: 'imei', label: 'IMEI' },
  { id: 16, show: true, name: 'registered_at', label: 'Дата регистрации' },
  { id: 17, show: true, name: 'installation_date', label: 'Дата установки' },
  { id: 18, show: true, name: 'last_online', label: 'Последний онлайн' },
  { id: 19, show: true, name: 'signal_intensity', label: 'Уровень сигнала (дБ)' },
  { id: 20, show: true, name: 'batarey_voltage', label: 'Напряжение батареи (В)' },
  { id: 21, show: true, name: 'cell_voltage', label: 'Напряжение ячейки (В)' },
];

export const DEVICE_RESULTS_TABLE_KEYS = [
  { id: 1, name: 'time_of_data', label: 'Время данных' },
  { id: 2, name: 'water_level', label: 'Уровень воды (м)' },
  { id: 3, name: 'water_conductivity', label: 'Электропроводность воды (µS/cm)' },
  { id: 4, name: 'water_temperature', label: 'Температура воды (°C)' },
  { id: 5, name: 'installed_depth', label: 'Установленная глубина (м)' },
  { id: 6, name: 'signal_intensity', label: 'Уровень сигнала (дБ)' },
  { id: 7, name: 'batarey_voltage', label: 'Напряжение батареи (В)' },
  { id: 8, name: 'cell_voltage', label: 'Напряжение ячейки (В)' },
  { id: 9, name: 'created_at', label: 'Дата создания' },
];

export const REGIONS_SELECT = [
  { id: 1, label: 'Andijon', value: 'andijon' },
  { id: 2, label: 'Toshkent', value: 'toshkent' },
];

export const USERS_TABLE_KEYS = [
  // { id: 1, name: 'id' },
  { id: 2, label: '', name: 'username', type: 'input' },
  { id: 3, label: '', name: 'lastname', type: 'input' },
  { id: 4, label: '', name: 'password', type: 'input' },
  { id: 5, label: '', name: 'email', type: 'input' },
  { id: 6, label: '', name: 'phone_number', type: 'input' },
  { id: 7, label: '', name: 'region', type: 'select' },
  { id: 8, label: '', name: 'district', type: 'select' },
  { id: 9, label: '', name: 'access_to_settings_l1', type: 'checkbox' },
  { id: 10, label: '', name: 'access_to_settings_l2', type: 'checkbox' },
  { id: 11, label: '', name: 'access_to_settings_l3', type: 'checkbox' },
  { id: 12, label: '', name: 'access_to_settings_l4', type: 'checkbox' },
  { id: 13, label: '', name: 'access_value_table_write', type: 'checkbox' },
  { id: 14, label: '', name: 'access_water_course_write', type: 'checkbox' },
];
