import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIPopover from '../../components/MUIPopover/MUIPopover';
import { changeTableValue } from '../../redux/slices/deviceValuesSlice';

function DeviceValueTable() {
  const dispatch = useDispatch();
  const {
    values: { table_value },
  } = useSelector(store => store);
  const [openPopover, setOpenPopover] = useState(null);
  const [currentItem, setCurrentItem] = useState({ idx: null, value: '' });

  const handleChangeResult = value => {
    const curRes = table_value?.map((res, idx) => (idx === currentItem?.idx ? value : res));

    setCurrentItem({ ...currentItem, value });
    dispatch(changeTableValue(curRes));
  };

  const rows = () => {
    const arr = [];
    for (let i = 0; i < Math.ceil(table_value.length / 10); i++) arr.push(i);
    return arr;
  };

  const cols = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <>
      <MUIPopover
        open={openPopover ? true : false}
        anchorEl={openPopover}
        onClose={() => setOpenPopover(null)}
      >
        <div className='p-0_5'>
          <input
            type='number'
            value={currentItem?.value}
            autoFocus={openPopover ? true : false}
            onChange={e => handleChangeResult(parseFloat(e.target.value))}
            className='border-none outline-none border-bottom-1px p-0_5 fs-xm md:w-5r'
          />
        </div>
      </MUIPopover>
      <div className='overflowX-scroll border-1px border-gray'>
        <table className='w-full border-collapse border-1px border-gray-light color-gray fs-xm md:w-40r'>
          <thead>
            <tr>
              <th className='border-2px border-gray py-1 w-calc-100-11'>H</th>
              {cols?.map(col => (
                <th key={col} className='border-2px border-gray py-1 w-calc-100-11'>
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table_value &&
              rows()?.map((row, idx1) => (
                <tr key={idx1} className='hover-bg-light'>
                  <td className='border-2px border-gray py-1 text-center w-calc-100-11'>{row}</td>
                  {cols?.map((col, idx2) => (
                    <td
                      key={idx2}
                      onClick={e => {
                        setOpenPopover(e.currentTarget);
                        setCurrentItem({
                          idx: row === 0 ? col : row * 10 + col,
                          value: table_value[row === 0 ? col : row * 10 + col],
                        });
                      }}
                      className='border-1px border-gray-light py-1 px-1 cursor-pointer w-calc-100-11'
                    >
                      {table_value[row === 0 ? col : row * 10 + col]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DeviceValueTable;
