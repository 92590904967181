import mem from 'mem';
import { axiosPublic } from './axiosPublic';

// eslint-disable-next-line consistent-return
const refreshTokenFn = async () => {
  const userInformation = JSON.parse(localStorage.getItem('UserInformation'));

  try {
    const response = await axiosPublic.post('token/refresh/', {
      refresh: userInformation?.refresh_token,
    });
    const { access, refresh } = response.data;

    if (!access) {
      localStorage.removeItem('UserInformation');
    }

    userInformation.access_token = access;
    userInformation.refresh_token = refresh;
    localStorage.setItem('UserInformation', JSON.stringify(userInformation));

    return access;
  } catch (error) {
    localStorage.removeItem('UserInformation');
    // window.location.reload();
  }
};

const maxAge = 600000;
export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
