import PropTypes from 'prop-types';
import { Popover } from '@mui/material';

function MUIPopover({ children, anchorOrigin, transformOrigin, ...params }) {
  return (
    <Popover anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} {...params}>
      {children}
    </Popover>
  );
}

MUIPopover.defaultProps = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

MUIPopover.propTypes = {
  children: PropTypes.node,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
};

export default MUIPopover;
