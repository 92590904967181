import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import Topbar from '../topbar/Topbar';

function MainPage() {
  const {
    sidebar: { status },
  } = useSelector(store => store);

  return (
    <div className='flex h-100vh'>
      <Sidebar />
      <div
        className={`${
          status ? 'w-calc-10r' : 'w-calc-4r'
        } transition-0_3s overflowY-scroll flex flex-col bg-light md:w-full`}
      >
        <Topbar />
        <Outlet />
      </div>
    </div>
  );
}

export default MainPage;
