import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../service/axiosPrivate';

const apiPath = 'users/';

const initialState = {
  users: [],
  region: [],
  district: [],
};

export const getRegions = createAsyncThunk('selects/getRegions', async () => {
  const response = await axiosPrivate.get(`${apiPath}region/`);
  return response.data;
});

export const getDistricts = createAsyncThunk('selects/getDistricts', async region_id => {
  const response = await axiosPrivate.get(`${apiPath}district/?region_id=${region_id}`);
  return response.data;
});

export const getUsers = createAsyncThunk('selects/getUsers', async () => {
  const response = await axiosPrivate.get(`${apiPath}accounts-list/`);
  return response.data;
});

export const selectsSlice = createSlice({
  name: 'selects',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRegions.fulfilled, (state, action) => {
        state.region = action.payload;
      })
      .addCase(getDistricts.fulfilled, (state, action) => {
        state.district = action.payload;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      });
  },
});

const { reducer, actions } = selectsSlice;

export const { openAndCloseSidebar, openAndCloseSwiper } = actions;

export default reducer;
