import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: false,
  swiperStatus: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openAndCloseSidebar: (state, action) => {
      state.status = action.payload;
    },
    openAndCloseSwiper: (state, action) => {
      state.swiperStatus = action.payload;
    },
  },
});

const { reducer, actions } = sidebarSlice;

export const { openAndCloseSidebar, openAndCloseSwiper } = actions;

export default reducer;
