import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { DEVICE_RESULTS_TABLE_KEYS } from '../../assets/constants/constants';
import MUIPagination from '../../components/MUIPagination/MUIPagination';

function JournalTable() {
  // const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageSize: 25,
    pageNumber: 1,
  });

  const handleChangePageSize = pageSize => {
    setPagination({ ...pagination, pageSize });
  };
  const handleChangePageNumber = pageNumber => {
    setPagination({ ...pagination, pageNumber });
  };

  return (
    <>
      <div className='overflowX-scroll border-1px border-gray-light'>
        <table className='w-min-full border-collapse border-1px border-gray-light color-gray'>
          <thead>
            <tr className='fs-md'>
              {DEVICE_RESULTS_TABLE_KEYS.map(key => (
                <th key={key.id} className='border-1px border-gray-light p-1'>
                  {key.label || key.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* {DEVICE_RESULTS_TABLE_BODY?.map(device => (
              <tr
                key={device?.id}
                className='fs-md hover-bg-light cursor-pointer'
                onClick={() => navigate(`/admin/device/${device?.id}`)}
              >
                {DEVICE_RESULTS_TABLE_KEYS.map(key => (
                  <td
                    key={key.id}
                    className='text-nowrap text-center border-1px border-gray-light p-1 '
                  >
                    {device[key.name]}
                  </td>
                ))}
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>
      <MUIPagination
        count={100}
        pageSize={pagination?.pageSize}
        pageNumber={pagination?.pageNumber}
        onChangePageSize={pageSize => handleChangePageSize(pageSize)}
        onChangePageNumber={pageNumber => handleChangePageNumber(pageNumber)}
      />
    </>
  );
}

export default JournalTable;
