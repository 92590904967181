import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { axiosPublic } from '../../service/axiosPublic';

const userInfoLS = JSON.parse(localStorage.getItem('userInfo'));

const initialState = {
  isLoading: false,
  userInfo: userInfoLS || null,
};

export const login = createAsyncThunk('auth/login', async user => {
  try {
    const response = await axiosPublic.post('token/', user);
    const userInfo = { ...response.data, ...jwtDecode(response.data.access) };
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    return userInfo;
  } catch (error) {
    toast.error(error.mesage);
    return null;
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.userInfo = null;
      localStorage.removeItem('userInfo');
    },
  },
  extraReducers: builter => {
    builter
      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.userInfo = { ...action.payload };
        state.isLoading = false;
      });
  },
});

const { reducer, actions } = authSlice;

export const { logout } = actions;

export default reducer;
