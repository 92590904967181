import { useState, useEffect } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { useDispatch, useSelector } from 'react-redux';
import MUILinerProgress from '../../components/MUILinerProgress/MUILinerProgress';
import { MUIMenu, MUIMenuItem } from '../../components/MUIMenu/MUIMenu';
import { getMaps } from '../../redux/slices/mapsSlice';

function MapPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    maps: { isLoading, maps },
  } = useSelector(store => store);
  const [mapMenu, setMapMenu] = useState(null);

  useEffect(() => {
    dispatch(getMaps());
  }, []);

  return (
    <div className='position-relative'>
      {isLoading && <MUILinerProgress />}
      <div className='mx-1 my-1_5'>
        <div className='flex justify-between my-1_5 fs-md'>
          <h1 className='fw-50 color-gray-dark'>Карта</h1>
          <button
            type='button'
            className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
            to='/'
          >
            Создать отчет
          </button>
        </div>
        <div className='border-1px border-radius-0_3r border-gray-light box-shadow overflow-hidden'>
          <div className='m-1 bg-light'>
            <button
              type='submit'
              onClick={e => setMapMenu(e.currentTarget)}
              className='bg-none border-none outline-none color-gray-light fs-xl active-outline-3px border-radius-0_3r transition-0_3s flex justify-center items-center'
            >
              <BsThreeDotsVertical />
            </button>
            <MUIMenu
              anchorEl={mapMenu}
              open={mapMenu ? true : false}
              onClose={() => setMapMenu(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MUIMenuItem>
                <span className='fs-md'>Action</span>
              </MUIMenuItem>
              <MUIMenuItem>
                <span className='fs-md'>Another action</span>
              </MUIMenuItem>
            </MUIMenu>
          </div>
          <hr className='border-gray-light border-1px' />
          <div className='bg-white h-100vh'>
            <YMaps>
              <Map
                width='100%'
                height='100%'
                defaultState={{
                  center: [41.311151, 69.279737],
                  zoom: 6,
                }}
              >
                {maps?.map(map => (
                  <Placemark
                    onClick={() => navigate(`/admin/device/${map?.id}`)}
                    geometry={[map?.latitude, map?.longitude]}
                  />
                ))}
              </Map>
            </YMaps>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapPage;
