import JournalTable from './JournalTable';

function JournalPage() {
  return (
    <div className='mx-1 mb-1'>
      {/* jurnal first section */}
      <div className='flex justify-between my-1_5 fs-md'>
        <h1 className='fw-50 color-gray-dark'>Журнал активности</h1>
        <button
          type='button'
          className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
          to='/'
        >
          Generate Report
        </button>
      </div>
      <div className='border-1px border-radius-0_3r border-gray-light box-shadow overflow-hidden'>
        <h4 className='color-blue fs-md p-1 fs-xm'>Список устройств</h4>
        <hr className='border-gray-light border-1px' />
        <div className='px-1 bg-white'>
          <div className='flex justify-end py-1 md:justify-center'>
            <input
              type='text'
              placeholder='Search'
              className='border-gray-light border-radius-0_3r p-0_5 border-1px md:w-full'
            />
          </div>
          <JournalTable />
        </div>
      </div>
    </div>
  );
}

export default JournalPage;
