import PropTypes from 'prop-types';
import { SwipeableDrawer } from '@mui/material';

function MUISwipeable({ children, ...params }) {
  return (
    <SwipeableDrawer anchor='left' open='left' {...params}>
      {children}
    </SwipeableDrawer>
  );
}

MUISwipeable.propTypes = {
  children: PropTypes.node,
};

export default MUISwipeable;
