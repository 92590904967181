import { useEffect, useState } from 'react';
import { BsDiagram2, BsWifi } from 'react-icons/bs';
import { FaCarBattery, FaDatabase } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MUIAutocomplete from '../../components/MUIAutocomplete/MUIAutocomplete';
import MUIInput from '../../components/MUIInput/MUIInput';
import MUISelect from '../../components/MUISelect/MUISelect';
import { changeDeviceData, getDeviceById, updateDevice } from '../../redux/slices/devicesSlice';
import { getDistricts, getRegions, getUsers } from '../../redux/slices/selectsSlice';

function DeviceEditPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    devices: { currentDevice },
    selects: { users, region, district },
  } = useSelector(store => store);
  const [device, setDevice] = useState({});

  const param = pathname.split('/')[4];

  const handleChangeDevice = (name, value) => {
    dispatch(changeDeviceData({ name, value }));
    setDevice({ ...device, [name]: value });
  };

  const handleSave = (id, data) => {
    dispatch(updateDevice({ id, device: data }));
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getDeviceById(param));
  }, []);

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getDistricts(currentDevice?.region_id));
  }, [currentDevice.region_id]);

  return (
    <div className='mx-1 my-1_5'>
      <div className='flex justify-between fs-md mt-0_5 mb-1'>
        <button
          type='button'
          className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
          onClick={() => navigate(-1)}
        >
          Назад
        </button>
        {/* <button
          type='button'
          className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
          to='/'
        >
          Создать отчет
        </button> */}
      </div>
      <div className='grid justify-between gap-col-1 gap-row-2_5 w-full grid-temp-col-1-49 md:grid-temp-col-1-100'>
        <div className='px-1 flex justify-between items-center border-left-4px border-radius-0_3r border-blue py-1_5 box-shadow-0-0-1-0_5'>
          <div>
            <h6 className='fs-xs color-blue'>СЕРИЙНЫЙ НОМЕР:</h6>
            <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.sn}</h6>
          </div>

          <h6 className='fs-2xl color-gray-light'>
            <BsDiagram2 />
          </h6>
        </div>
        <div className='px-1 flex justify-between items-center  border-left-4px border-radius-0_3r border-green py-1_5 box-shadow-0-0-1-0_5'>
          <div>
            <h6 className='fs-xs color-green'>IMEI</h6>
            <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.imei}</h6>
          </div>

          <h6 className='fs-2xl color-gray-light'>
            <BsWifi />
          </h6>
        </div>
        <div className='px-1 flex justify-between items-center  border-left-4px border-radius-0_3r border-cyan py-1_5 box-shadow-0-0-1-0_5'>
          <div>
            <h6 className='fs-xs color-cyan'>УРОВЕНЬ ЗАРЯДА БАТАРЕИ</h6>
            <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.batarey_voltage}</h6>
          </div>
          <div>
            <h6 className='fs-2xl color-gray-light'>
              <FaCarBattery />
            </h6>
          </div>
        </div>
        <div className='px-1 flex justify-between items-center border-left-4px border-radius-0_3r border-orange py-1_5 box-shadow-0-0-1-0_5'>
          <div>
            <h6 className='fs-xs color-orange'>ДАТА СОЗДАНИЯ</h6>
            <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.created_at}</h6>
          </div>

          <h6 className='fs-2xl color-gray-light'>
            <FaDatabase />
          </h6>
        </div>
      </div>
      <div className='border-1px border-radius-0_3r border-gray-light box-shadow overflow-hidden mt-2_5'>
        <h4 className='color-blue fs-md p-1 fs-xm border-bottom-1px border-gray-light fw-500'>
          Настройки прибора
        </h4>
        <div className='px-1 py-1_5 bg-white'>
          <MUIInput
            fullWidth
            type='search'
            label='Название объекта'
            value={currentDevice?.object_name || ''}
            onChange={e => handleChangeDevice('object_name', e.target.value)}
          />
          <div className='grid grid-temp-col-1-49 gap-1 mt-1 md:grid-temp-col-1-100'>
            <MUIInput
              fullWidth
              type='search'
              label='Название поста'
              value={currentDevice?.post_name || ''}
              onChange={e => handleChangeDevice('post_name', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Название пикета'
              value={currentDevice?.pk_name || ''}
              onChange={e => handleChangeDevice('pk_name', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Ответственное лицо'
              value={currentDevice?.fresponsible_person || ''}
              onChange={e => handleChangeDevice('fresponsible_person', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Номер телефона ответственного лица'
              value={currentDevice?.phone_number || ''}
              onChange={e => handleChangeDevice('phone_number', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Номер сим карты прибора'
              value={currentDevice?.sim_number || ''}
              onChange={e => handleChangeDevice('sim_number', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Дата установки прибора'
              value={currentDevice?.installation_date || ''}
              onChange={e => handleChangeDevice('installation_date', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Адрес расположения модема'
              value={currentDevice?.address || ''}
              onChange={e => handleChangeDevice('address', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Расстояние от датчика до дна канавы (м)'
              value={currentDevice?.measure_distance || ''}
              onChange={e => handleChangeDevice('measure_distance', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Местоположение(LAT)'
              value={currentDevice?.latitude || ''}
              onChange={e => handleChangeDevice('latitude', e.target.value)}
            />
            <MUIInput
              fullWidth
              type='search'
              label='Местоположение(LONG)'
              value={currentDevice?.longitude || ''}
              onChange={e => handleChangeDevice('longitude', e.target.value)}
            />
          </div>
          <div className='my-1 grid grid-temp-col-1-49 gap-1 mt-1 md:grid-temp-col-1-100'>
            <MUISelect
              fullWidth
              label='Область'
              options={region || []}
              value={currentDevice?.region_id || ''}
              optionParam={{ value: 'id', label: 'region_name' }}
              onChange={e => handleChangeDevice('region_id', e.target.value)}
            />
            <MUISelect
              fullWidth
              label='Район'
              options={district || []}
              value={currentDevice?.district_id || ''}
              optionParam={{ value: 'id', label: 'district_name' }}
              onChange={e => handleChangeDevice('district_id', e.target.value)}
            />
          </div>
          <MUIAutocomplete
            options={users || []}
            label='Прикрепить плзвателю'
            getOptionLabel={option => option.username}
            value={currentDevice?.users || []}
            onChange={(event, value) => {
              handleChangeDevice('users', value);
            }}
          />
        </div>

        <h4 className='color-blue fs-md p-1 fs-xm border-bottom-1px border-gray-light fw-500'>
          Настройки периода времени модема
        </h4>
        <div className='px-1 py-1_5 bg-white grid grid-temp-col-1-49 gap-1 md:grid-temp-col-1-100'>
          <MUISelect
            fullWidth
            options={region}
            label='Установка времени пробуждения модема'
            value={currentDevice?.region_id || ''}
            optionParam={{ value: 'id', label: 'region_name' }}
          />
          <MUISelect
            fullWidth
            options={district}
            label='Установка периода опроса датчика'
            value={currentDevice?.district_id || ''}
            optionParam={{ value: 'id', label: 'district_name' }}
          />
        </div>

        <h4 className='color-blue fs-md p-1 fs-xm border-bottom-1px border-gray-light fw-500'>
          Настройки интеграции
        </h4>
        <div className='px-1 py-1_5 bg-white'>
          <MUIInput
            fullWidth
            type='search'
            label='Введите токен'
            value={currentDevice?.integration_token || ''}
            onChange={e => handleChangeDevice('integration_token', e.target.value)}
          />
        </div>

        <div className='p-1 border-top-1px border-gray-light fw-500 flex items-center justify-end gap-1'>
          <button
            type='button'
            onClick={() => navigate(-1)}
            className='px-1 py-0_5 fs-xm border-radius-0_3r border-none color-white bg-blue'
          >
            Отменить
          </button>
          <button
            type='button'
            onClick={() => handleSave(param, device)}
            className='px-1 py-0_5 fs-xm border-radius-0_3r border-none color-white bg-green'
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeviceEditPage;
