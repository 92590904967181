import PropTypes from 'prop-types';

import { Modal } from '@mui/material';

function MUIModal({ children, ...params }) {
  return (
    <Modal {...params} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {children}
    </Modal>
  );
}

MUIModal.propTypes = {
  children: PropTypes.node,
};

export default MUIModal;
