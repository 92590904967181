import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { debounce } from 'lodash';
import { CircularProgress, Fade, Menu, MenuItem } from '@mui/material';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DevicesTable from './DevicesTable';
import BarChart from '../../components/Charts/BarChart';
import { MUIMenu, MUIMenuItem } from '../../components/MUIMenu/MUIMenu';
import MUILinerProgress from '../../components/MUILinerProgress/MUILinerProgress';
import { getMaps } from '../../redux/slices/mapsSlice';
import {
  changeColumns,
  changeSearch,
  downloadReport,
  getDevices,
} from '../../redux/slices/devicesSlice';

function DevicesPage() {
  const fileElementsCount = 500;
  const dispatch = useDispatch();
  const {
    maps: { maps },
    devices: { isLoading, count, search, order, columns, pagination },
  } = useSelector(store => store);
  const [mapMenu, setMapMenu] = useState(null);
  const [tableMenu, setTableMenu] = useState(null);
  const [chartMenu, setChartMenu] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const debouncedSearch = useCallback(
    debounce(text => dispatch(changeSearch(text)), 512),
    [search],
  );

  const handleOpenMenu = anchorEl => setOpenMenu(anchorEl);

  const handleCloseMenu = () => setOpenMenu(null);

  const handleDivideFile = total => {
    const index = [];
    for (let i = 0; i < Math.ceil(total / fileElementsCount); i++) index.push(i);
    return index;
  };

  const handleDownloadExcel = async data => {
    try {
      setDownloadLoading(true);
      const file = await downloadReport(data);
      triggerBase64Download(
        `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${file.bytes}`,
        `${new Date().toISOString().substr(0, 10)}, ${data?.offset} - ${data.offset + data.count}`,
      );
      setDownloadLoading(false);
      console.clear();
    } catch (e) {
      setDownloadLoading(false);
    }
  };

  const handleChangeSearch = value => {
    debouncedSearch(value);
  };

  useEffect(() => {
    dispatch(getMaps());
  }, []);

  useEffect(() => {
    dispatch(getDevices({ order, pagination, search }));
  }, [pagination, order, search]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <>
      <Menu
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
        open={openMenu ? true : false}
      >
        <div className='h-max-10r'>
          {handleDivideFile(count).map(idx => (
            <MenuItem
              key={idx}
              onClick={() =>
                handleDownloadExcel({
                  offset: idx * fileElementsCount,
                  count: fileElementsCount,
                })
              }
            >
              {idx * fileElementsCount} ⇾ {(idx + 1) * fileElementsCount}
            </MenuItem>
          ))}
        </div>
      </Menu>
      <div className='position-relative'>
        {isLoading && <MUILinerProgress />}
        <div className='mx-1 mb-1'>
          <div className='flex items-center justify-between my-1_5 fs-md'>
            <h1 className='fw-50 color-gray-dark'>Приборная панель</h1>
            <button
              type='button'
              disabled={downloadLoading}
              onClick={e =>
                count > fileElementsCount
                  ? handleOpenMenu(e.currentTarget)
                  : handleDownloadExcel({ offset: 0, count })
              }
              className={`position-relative border-none outline-none fs-md color-white px-1 py-0_5 border-radius-0_3r ${
                downloadLoading ? 'bg-gray-light' : 'bg-blue'
              }`}
            >
              <span>Создать отчет</span>
              {downloadLoading && (
                <CircularProgress
                  size={20}
                  thickness={7}
                  // color='error'
                  style={{ position: 'absolute', left: '47%' }}
                />
              )}
            </button>
          </div>
          <div className='border-1px border-radius-0_3r border-gray-light box-shadow overflow-hidden'>
            <div className='flex justify-between items-center p-1'>
              <h4 className='color-blue fs-md fs-xm'>Список устройств</h4>

              <button
                type='submit'
                onClick={e => setTableMenu(e.currentTarget)}
                className='bg-none border-none outline-none color-gray-light fs-xl active-outline-3px border-radius-0_3r transition-0_3s flex justify-center items-center'
              >
                <BsThreeDotsVertical />
              </button>
              <MUIMenu
                anchorEl={tableMenu}
                open={tableMenu ? true : false}
                className='h-max-58vh'
                onClose={() => setTableMenu(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {columns?.map(key => (
                  <label key={key?.id} htmlFor={key?.name}>
                    <MUIMenuItem className='flex items-center gap-0_5'>
                      <input
                        id={key?.name}
                        type='checkbox'
                        checked={key?.show}
                        onChange={() => dispatch(changeColumns({ id: key?.id }))}
                      />
                      <span className='fs-md'>{key?.name}</span>
                    </MUIMenuItem>
                  </label>
                ))}
              </MUIMenu>
            </div>
            <hr className='border-gray-light border-1px' />
            <div className='px-1 bg-white'>
              <div className='flex justify-end py-1'>
                <input
                  type='text'
                  placeholder='Search'
                  onChange={e => handleChangeSearch(e.target.value)}
                  className='border-gray-light border-radius-0_3r p-0_5 border-1px'
                />
              </div>
              <DevicesTable />
            </div>
          </div>

          <div className='flex justify-between gap-1 mt-1 md:flex-col'>
            <div className='border-1px border-gray-light w-50 border-box border-radius-0_3r overflow-hidden box-shadow md:w-full'>
              <div className='flex justify-between items-center p-1 color-blue bg-light'>
                <h2>Карта</h2>
                <button
                  type='submit'
                  onClick={e => setMapMenu(e.currentTarget)}
                  className='bg-none border-none outline-none color-gray-light fs-xl active-outline-3px border-radius-0_3r transition-0_3s flex justify-center items-center'
                >
                  <BsThreeDotsVertical />
                </button>
                <MUIMenu
                  anchorEl={mapMenu}
                  open={mapMenu ? true : false}
                  onClose={() => setMapMenu(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MUIMenuItem>
                    <span className='fs-md'>Action</span>
                  </MUIMenuItem>
                  <MUIMenuItem>
                    <span className='fs-md'>Another action</span>
                  </MUIMenuItem>
                </MUIMenu>
              </div>
              <hr className='border-gray-light border-1px' />
              <div className='w-full h-full'>
                <YMaps>
                  <Map
                    width='100%'
                    height='100%'
                    defaultState={{
                      zoom: 6,
                      center: [41.311151, 65.279737],
                    }}
                  >
                    {maps?.map(map => (
                      <Placemark key={map?.id} geometry={[map?.latitude, map?.longitude]} />
                    ))}
                  </Map>
                </YMaps>
              </div>
            </div>

            <div className='border-1px border-gray-light w-50 border-box border-radius-0_3r overflow-hidden box-shadow md:w-full'>
              <div className='flex justify-between items-center p-1 color-blue bg-light'>
                <h2>График</h2>
                <button
                  type='submit'
                  onClick={e => setChartMenu(e.currentTarget)}
                  className='bg-none border-none outline-none color-gray-light fs-xl active-outline-3px border-radius-0_3r transition-0_3s flex justify-center items-center'
                >
                  <BsThreeDotsVertical />
                </button>
                <MUIMenu
                  anchorEl={chartMenu}
                  open={chartMenu ? true : false}
                  onClose={() => setChartMenu(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MUIMenuItem>
                    <span className='fs-md'>Action</span>
                  </MUIMenuItem>
                  <MUIMenuItem>
                    <span className='fs-md'>Another action</span>
                  </MUIMenuItem>
                </MUIMenu>
              </div>
              <hr className='border-gray-light border-1px' />
              <div className='p-1'>
                <BarChart labels={[]} data={[]} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='w-full py-2 bg-light box-shadow-top text-center'>
        <h3 className='color-gray fw-100'>Copyright © Maxwell.uz 2021</h3>
      </div> */}
    </>
  );
}

export default DevicesPage;
