import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from './pages/login/Login';
import MainPage from './pages/main/Main';
import DevicesPage from './pages/devices/Devices';
import Device from './pages/devices/Device';
import JournalPage from './pages/journal/Journal';
import MapPage from './pages/map/Map';
import DeviceEditPage from './pages/devices/DeviceEdit';
import DeviceValuePage from './pages/devices/DeviceValue';

import './App.css';
import NotFound from './pages/notFound/NotFound';
import Users from './pages/users/Users';

function App() {
  const navigate = useNavigate();
  const {
    auth: { userInfo },
  } = useSelector(store => store);

  useEffect(() => {
    userInfo === null && navigate('/');
  }, [userInfo]);

  return (
    <Routes>
      <Route index element={<LoginPage />} />
      {userInfo?.access && (
        <>
          <Route path='admin' element={<MainPage />}>
            <Route>
              <Route index element={<DevicesPage />} />
              <Route path='device/:id' element={<Device />} />
              <Route path='device/edit/:id' element={<DeviceEditPage />} />
              <Route path='device/value-table/:id' element={<DeviceValuePage />} />
            </Route>
            <Route path='journal' element={<JournalPage />} />
            <Route path='map' element={<MapPage />} />
            <Route path='users' element={<Users />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </>
      )}
    </Routes>
  );
}

export default App;
