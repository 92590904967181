import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../service/axiosPrivate';
import { DEVICES_TABLE_KEYS } from '../../assets/constants/constants';

const devicesColumnsLS = JSON.parse(localStorage.getItem('devicesColumns'));

const apiPath = 'devices/device/';

const initialState = {
  isLoading: false,
  count: 0,
  search: '',
  devices: [],
  waterCourse: 0,
  currentDevice: {},
  currentDeviceDefault: {},
  order: { key: 'id', status: '' },
  columns: devicesColumnsLS || DEVICES_TABLE_KEYS,
  pagination: { pageNumber: 1, pageSize: 100 },
};

export const getDevices = createAsyncThunk('devices/get', async ({ pagination, order, search }) => {
  const response = await axiosPrivate.get(
    `${apiPath}?p=${pagination.pageNumber}&page_size=${
      pagination.pageSize
    }&search=${search}&ordering=${order.key + order.status}`,
  );
  return response.data;
});

export const getDeviceById = createAsyncThunk('devices/getById', async id => {
  const response = await axiosPrivate.get(`${apiPath}${id}/`);
  return response.data;
});

export const getDeviceWaterCourse = createAsyncThunk('devices/getDeviceWaterCourse', async id => {
  const response = await axiosPrivate.get(`devices/device-water-course/${id}/`);
  return response.data;
});

export const updateDeviceWaterCourse = createAsyncThunk(
  'devices/updateDeviceWaterCourse',
  async data => {
    const response = await axiosPrivate.put(`devices/device-water-course/${data?.id}/`, data);
    return response.data;
  },
);

export const updateDevice = createAsyncThunk('devices/update', async ({ id, device }) => {
  const response = await axiosPrivate.patch(`${apiPath}${id}/`, device);
  return response.data;
});

export const deleteDevice = createAsyncThunk('devices/delete', async id => {
  const response = await axiosPrivate.delete(apiPath + id);
  return response.data;
});

export const downloadReport = async ({ offset, count }) => {
  const response = await axiosPrivate.get(`reports/device-excel/?offset=${offset}&count=${count}`);
  return response.data;
};

const handleChangeColumns = (state, payload) => {
  const cols = state.columns.map(col =>
    col.id === payload.id ? { ...col, show: !col.show } : col,
  );
  localStorage.setItem('devicesColumns', JSON.stringify(cols));
  return cols;
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    changeColumns: (state, action) => {
      state.columns = handleChangeColumns(state, action.payload);
    },
    changePagination: (state, action) => {
      state.pagination =
        action.payload.name === 'pageSize'
          ? { [action.payload.name]: action.payload.value, pageNumber: 1 }
          : { [action.payload.name]: action.payload.value, pageSize: state.pagination.pageSize };
    },
    changeOrder: (state, action) => {
      state.order =
        state.order.key === action.payload
          ? { key: state.order.key, status: state.order.status ? '' : '-' }
          : { key: action.payload, status: '' };
    },
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeDeviceData: (state, action) => {
      state.currentDevice[action.payload.name] = action.payload.value;
    },
    changeWaterCourse: (state, action) => {
      state.waterCourse = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDevices.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.devices = action.payload.results;
        state.count = action.payload.count;
        state.isLoading = false;
      })
      .addCase(getDeviceById.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDeviceById.fulfilled, (state, action) => {
        state.currentDevice = action.payload;
        state.currentDeviceDefault = action.payload;
        state.isLoading = false;
      })
      .addCase(updateDevice.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateDevice.fulfilled, (state, action) => {
        state.currentDevice = action.payload;
        state.currentDeviceDefault = action.payload;
        state.isLoading = false;
      })
      .addCase(deleteDevice.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        state.users = action.payload;
        state.currentUser = action?.payload[0];
        state.currentUserDefault = action?.payload[0];
        state.isLoading = false;
      })
      .addCase(getDeviceWaterCourse.fulfilled, (state, action) => {
        state.waterCourse = action.payload.water_course;
      });
  },
});

const { reducer, actions } = devicesSlice;

export const {
  changeOrder,
  changeSearch,
  changeColumns,
  changePagination,
  changeDeviceData,
  changeWaterCourse,
} = actions;

export default reducer;
