import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { ROUTES } from '../../assets/constants/constants';
import { openAndCloseSidebar, openAndCloseSwiper } from '../../redux/slices/sidebarSlice';
import MUISwipeable from '../../components/MUISwipeable/MUISwipeable';

function Sidebar() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    sidebar: { status, swiperStatus },
  } = useSelector(store => store);

  const param = pathname.split('/')[2];

  const handleOpenAndCloseSidebar = () => {
    dispatch(openAndCloseSidebar(!status));
  };
  const handleOpenAndCloseSwiper = () => {
    dispatch(openAndCloseSwiper(!swiperStatus));
  };

  return (
    <>
      <div
        className={`${
          status ? 'w-10r' : 'w-4r'
        } transition-0_3s h-100vh flex flex-col bg-blue p-1 z-index-2 md:hidden`}
      >
        <Link to='/admin' className='text-decoration-none'>
          <h1 className='color-light fs-2xl m-0_2 cursor-pointer'>M</h1>
        </Link>
        <div className='flex flex-col gap-1 mt-1_5'>
          {ROUTES.map(route => (
            <Link
              key={route.id}
              to={route.pathname}
              className={`flex items-center fs-2xl gap-1 cursor-pointer text-decoration-none hover-color-white ${
                param === route.label ? 'color-white' : 'color-white-30'
              }`}
            >
              {route.icon}
              {status && <p className='fs-xm'>{route.name}</p>}
            </Link>
          ))}
        </div>
        <span
          className='w-2r h-2r border-circle flex justify-center items-center bg-white-30 fs-xm mt-2 color-white cursor-pointer transition-0_3s hover-color-blue hover-bg-white'
          onClick={handleOpenAndCloseSidebar}
        >
          {status ? <BsChevronLeft /> : <BsChevronRight />}
        </span>
      </div>
      <MUISwipeable
        open={swiperStatus}
        onClose={handleOpenAndCloseSwiper}
        onOpen={handleOpenAndCloseSwiper}
      >
        <div className={` w-10r h-100vh flex-col bg-blue p-1 z-index-2`}>
          <Link to='/admin' className='text-decoration-none'>
            <h1 className='color-light fs-2xl m-0_2 cursor-pointer'>M</h1>
          </Link>
          <div className='flex flex-col gap-1 mt-1_5'>
            {ROUTES.map(route => (
              <Link
                key={route.id}
                to={route.pathname}
                onClick={handleOpenAndCloseSwiper}
                className={`flex items-center fs-2xl gap-1 cursor-pointer text-decoration-none hover-color-white ${
                  param === route.label ? 'color-white' : 'color-white-30'
                }`}
              >
                {route.icon}
                <p className='fs-xm'>{route.name}</p>
              </Link>
            ))}
          </div>
          {/* <span
            className='w-2r h-2r border-circle flex justify-center items-center bg-white-30 fs-xm mt-2 color-white cursor-pointer transition-0_3s hover-color-blue hover-bg-white'
            onClick={handleOpenAndCloseSidebar}
          >
            {status ? <BsChevronLeft /> : <BsChevronRight />}
          </span> */}
        </div>
      </MUISwipeable>
    </>
  );
}

export default Sidebar;
