import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../service/axiosPrivate';

const apiPath = 'devices/indicators/';

const initialState = {
  isLoading: false,
  count: 0,
  search: '',
  indicators: [],
  pagination: { pageNumber: 1, pageSize: 100 },
};

export const getDeviceIndicators = createAsyncThunk(
  'devices/getDevicesIndicators',
  async ({ id, pagination, search }) => {
    const response = await axiosPrivate.get(
      `${apiPath}?device=${id}&p=${pagination?.pageNumber}&page_size=${pagination?.pageSize}&search=${search}`,
    );
    return { data: response.data };
  },
);

export const deviceIndicatorsSlice = createSlice({
  name: 'deviceIndicators',
  initialState,
  reducers: {
    changePagination: (state, action) => {
      state.pagination =
        action.payload.name === 'pageSize'
          ? { [action.payload.name]: action.payload.value, pageNumber: 1 }
          : { [action.payload.name]: action.payload.value, pageSize: state.pagination.pageSize };
    },
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDeviceIndicators.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDeviceIndicators.fulfilled, (state, action) => {
        // state.pagination = action.payload.pagination;
        state.indicators = action.payload.data.results;
        state.count = action.payload.data.count;
        state.isLoading = false;
      });
  },
});

const { reducer, actions } = deviceIndicatorsSlice;

export const { changePagination, changeSearch } = actions;

export default reducer;
