import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';

function MUIPagination({ count, pageSize, pageNumber, onChangePageSize, onChangePageNumber }) {
  const pageCount = count > pageSize ? Math.ceil(count / pageSize) : 1;

  return (
    <div className='flex justify-between py-1 fs-md color-gray md:flex-col md:items-center'>
      <div className='flex items-center gap-0_5 md:mb-1'>
        <p>Показать:</p>
        <select
          className='py-0_2 border-gray-light border-radius-0_3r'
          value={pageSize}
          onChange={e => onChangePageSize(parseInt(e.target.value, 10))}
        >
          <option value='100'>100</option>
          <option value='200'>200</option>
          <option value='300'>300</option>
          <option value='400'>400</option>
          <option value='500'>500</option>
        </select>
        <p className='hidden ml-1 md:block'>
          Показано с {pageNumber} по {pageCount}
        </p>
      </div>
      <div className='flex items-center gap-1 md:flex-col'>
        <p className='md:hidden'>
          Показано с {pageNumber} по {pageCount}
        </p>
        <Pagination
          shape='rounded'
          count={pageCount}
          page={pageNumber}
          variant='outlined'
          onChange={(event, page) => onChangePageNumber(page)}
        />
      </div>
    </div>
  );
}

MUIPagination.propTypes = {
  count: PropTypes.number,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  onChangePageSize: PropTypes.func,
  onChangePageNumber: PropTypes.func,
};

export default MUIPagination;
