import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../service/axiosPrivate';

const apiPath = 'users/accounts/';

const initialState = {
  isLoading: false,
  users: false,
  currentUser: {},
  currentUserDefault: {},
};

export const getUsers = createAsyncThunk('users/get', async () => {
  const response = await axiosPrivate.get(apiPath);
  return response.data;
});

export const getUserById = createAsyncThunk('users/getById', async id => {
  const response = await axiosPrivate.get(`${apiPath}${id}/`);
  return response.data;
});

export const addUser = createAsyncThunk('users/add', async user => {
  const currentUser = await axiosPrivate.post(apiPath, user);
  const users = await axiosPrivate.get(apiPath);
  return { users: users.data, currentUser: currentUser.data.user };
});

export const updateUser = createAsyncThunk('users/update', async ({ id, user }) => {
  const response = await axiosPrivate.patch(`${apiPath}${id}/`, user);
  return response.data;
});

export const deleteUser = createAsyncThunk('users/delete', async id => {
  const response = await axiosPrivate.delete(apiPath + id);
  return response.data;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsers.pending, state => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.currentUser = action?.payload[0];
        state.currentUserDefault = action?.payload[0];
        state.isLoading = false;
      })
      .addCase(getUserById.pending, state => {
        state.isLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.currentUserDefault = action.payload;
        state.isLoading = false;
      })
      .addCase(addUser.pending, state => {
        state.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.currentUser = action.payload.currentUser;
        state.currentUserDefault = action.payload.currentUser;
        state.isLoading = false;
      })
      .addCase(updateUser.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.currentUserDefault = action.payload;
        state.isLoading = false;
      })
      .addCase(deleteUser.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = action.payload;
        state.currentUser = action?.payload[0];
        state.currentUserDefault = action?.payload[0];
        state.isLoading = false;
      });
  },
});

const { reducer } = usersSlice;

export default reducer;
