import PropTypes from 'prop-types';
import MuiAccordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/material/styles';
import './MUITreeAccordion.scss';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
);

function MUITreeAccordionItem({ item, onChangeCurrentUser }) {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls='panel1d-content'
        id='panel1d-header'
        expandIcon={
          item?.child?.length > 0 ? (
            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
          ) : (
            <div style={{ width: '0.9rem' }} />
          )
        }
        onClick={() =>
          onChangeCurrentUser({
            id: item?.id,
            username: item?.username,
          })
        }
      >
        <Typography>
          <PersonIcon fontSize='small' /> {item?.username}
        </Typography>
      </AccordionSummary>
      {item?.child?.length > 0 && (
        <AccordionDetails>
          {item?.child?.map(ch => (
            <MUITreeAccordionItem
              item={ch}
              key={ch?.id}
              onChangeCurrentUser={user => onChangeCurrentUser(user)}
            />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
}

function MUITreeAccordion({ data, onChangeCurrentUser }) {
  return (
    <div className='mini_accordion'>
      {data?.map(item => (
        <MUITreeAccordionItem
          item={item}
          key={item?.id}
          onChangeCurrentUser={user => onChangeCurrentUser(user)}
        />
      ))}
    </div>
  );
}

MUITreeAccordionItem.propTypes = {
  item: PropTypes.object,
  onChangeCurrentUser: PropTypes.func,
};

MUITreeAccordion.propTypes = {
  data: PropTypes.array,
  onChangeCurrentUser: PropTypes.func,
};

export default MUITreeAccordion;
