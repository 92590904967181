import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../service/axiosPrivate';

const apiPath = 'devices/device-table-value/';

const initialState = {
  id: 0,
  sn: 0,
  imei: 0,
  table_value: [],
  isLoading: false,
};

export const getDeviceValues = createAsyncThunk('deviceValues/getDeviceValues', async id => {
  const response = await axiosPrivate.get(`${apiPath}${id}/`);
  return response.data;
});

export const updateDeviceValue = createAsyncThunk(
  'deviceValues/updateDeviceValues',
  async ({ id, data }) => {
    const response = await axiosPrivate.put(`${apiPath}${id}/`, { table_value: data });
    return response.data;
  },
);

export const deviceValuesSlice = createSlice({
  name: 'deviceValues',
  initialState,
  reducers: {
    changeTableValue: (state, action) => {
      state.table_value = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDeviceValues.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDeviceValues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.id = action.payload.id;
        state.sn = action.payload.sn;
        state.imei = action.payload.imei;
        state.table_value = action.payload.table_value;
      })
      .addCase(updateDeviceValue.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateDeviceValue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.id = action.payload.id;
        state.sn = action.payload.sn;
        state.imei = action.payload.imei;
        state.table_value = action.payload.table_value;
      });
  },
});

const { reducer, actions } = deviceValuesSlice;

export const { changeTableValue } = actions;

export default reducer;
