import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MUIModal from '../../components/MUIModal/MUIModal';
import MUITreeAccordion from '../../components/MUITreeAccordion/MUITreeAccordion';
import MUILinerProgress from '../../components/MUILinerProgress/MUILinerProgress';
import { USERS_TABLE_KEYS } from '../../assets/constants/constants';
import {
  addUser,
  deleteUser,
  getUserById,
  getUsers,
  updateUser,
} from '../../redux/slices/usersSlice';
import { getDistricts, getRegions } from '../../redux/slices/selectsSlice';

function Users() {
  const dispatch = useDispatch();
  const {
    users: { isLoading, users, currentUser },
    selects,
  } = useSelector(store => store);
  const [action, setAction] = useState('view');
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeCurrentUser = userData => {
    dispatch(getUserById(userData?.id));
  };

  const handleChange = (name, value) => {
    name === 'region' || name === 'district'
      ? setUser({ ...user, [`${name}_id`]: value })
      : setUser({ ...user, [name]: value });
    name === 'region' && dispatch(getDistricts(value));
  };

  const handleSave = data => {
    try {
      dispatch(action === 'add' ? addUser(data) : updateUser({ id: data?.id, user: data?.user }));
      setAction('view');
    } catch (error) {
      error;
    }
  };

  const handleDelete = id => {
    try {
      dispatch(deleteUser(id));
      handleClose();
    } catch (error) {
      error;
    }
  };

  const handleCancel = () => {
    setAction('view');
  };

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (currentUser.id) {
      dispatch(getRegions());
      dispatch(getDistricts(currentUser?.region_id));
    }
  }, [currentUser]);

  return (
    <>
      <MUIModal open={open}>
        <div className='flex flex-col gap-1 bg-white border-radius-0_3r p-1_5 outline-none'>
          <h1>Do you want to delete this user?</h1>
          <div className='flex gap-1'>
            <button
              type='button'
              onClick={() => handleDelete(currentUser?.id)}
              className='w-50 px-1 py-0_5 bg-blue border-none color-white fw-bold border-radius-0_3r'
            >
              Yes
            </button>
            <button
              type='button'
              onClick={handleClose}
              className='w-50 px-1 py-0_5 bg-warning border-none color-white fw-bold border-radius-0_3r'
            >
              No
            </button>
          </div>
        </div>
      </MUIModal>
      <div className='position-relative'>
        {isLoading && <MUILinerProgress />}
        <div className='mx-1 mb-1 position-relative '>
          <div className='flex justify-between my-1_5 fs-md sm:flex-col sm:gap-4'>
            <h1 className='fw-50 color-gray-dark'>Журнал активности</h1>
            <div className='flex items-center gap-0_5 sm:w-full'>
              <button
                type='button'
                onClick={() => {
                  action === 'view' && setAction('add');
                  action === 'add' && handleSave({ parent_id: currentUser?.id, ...user });
                  action === 'edit' && handleSave({ id: currentUser?.id, user });
                }}
                className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r sm:w-full'
              >
                {action === 'view' && 'Add'}
                {(action === 'add' || action === 'edit') && 'Save'}
              </button>
              {action === 'view' && (
                <button
                  type='button'
                  onClick={() => {
                    setAction('edit');
                    dispatch(getDistricts(currentUser?.region_id));
                  }}
                  className='border-none outline-none fs-md color-white bg-warning px-1 py-0_5 border-radius-0_3r sm:w-full'
                >
                  Edit
                </button>
              )}
              <button
                type='button'
                onClick={() => {
                  action === 'view' && handleOpen();
                  (action === 'add' || action === 'edit') && handleCancel();
                }}
                className='border-none outline-none fs-md color-white bg-danger px-1 py-0_5 border-radius-0_3r sm:w-full'
              >
                {action === 'view' && 'Delete'}
                {(action === 'add' || action === 'edit') && 'Cancel'}
              </button>
            </div>
          </div>
          <div className='border-1px border-radius-0_3r border-gray-light box-shadow overflow-hidden'>
            <h4 className='color-blue fs-md p-1 fs-xm'>Список устройств</h4>
            <hr className='border-gray-light border-1px' />
            <div className='px-1 bg-white'>
              <div className='flex justify-end py-1 md:justify-center'>
                <input
                  type='text'
                  placeholder='Search'
                  className='border-gray-light border-radius-0_3r p-0_5 border-1px md:w-full'
                />
              </div>
              {users && (
                <div className='flex py-1 color-gray md:flex-col'>
                  <div className='w-30 h-max-100vh overflow-scroll md:w-full md:h-max-30r'>
                    <MUITreeAccordion
                      data={users}
                      onChangeCurrentUser={currUser => handleChangeCurrentUser(currUser)}
                    />
                  </div>
                  <div className='w-70 px-2 md:w-full'>
                    <table className='w-full fs-md border-collapse'>
                      <tbody>
                        {USERS_TABLE_KEYS.map(key => (
                          <tr key={key?.id}>
                            <td
                              className={`w-50 fw-bold border-bottom-1px h-3_5r pt-1 sm:hidden ${
                                action === 'add' || action === 'edit' ? 'md:hidden' : ''
                              }`}
                            >
                              {key.label || key.name}
                            </td>
                            <td className='w-50 text-end border-bottom-1px h-3_5r pt-1 sm:w-full sm:flex sm:flex-col '>
                              <label htmlFor={key.name}>
                                <span
                                  className={`hidden fw-bold sm:screen ${
                                    action === 'add' || action === 'edit' ? 'md:screen' : ''
                                  } `}
                                >
                                  {key.label || key.name}
                                </span>
                              </label>
                              {action === 'add' || action === 'edit' ? (
                                <>
                                  {key?.type === 'input' && (
                                    <input
                                      id={key.name}
                                      type='search'
                                      className='w-full p-0_5'
                                      placeholder={key.name}
                                      onChange={e => handleChange(key.name, e.target.value)}
                                      defaultValue={action === 'edit' ? currentUser[key.name] : ''}
                                    />
                                  )}
                                  {key?.type === 'select' && (
                                    <select
                                      className='w-full p-0_5'
                                      onChange={e => handleChange(key.name, e.target.value)}
                                      defaultValue={
                                        action === 'edit' ? currentUser[`${key.name}_id`] || '' : ''
                                      }
                                    >
                                      <option value='' disabled>
                                        Choose {key.name}
                                      </option>
                                      {selects[key.name].map(option => (
                                        <option key={option?.id} value={option.id}>
                                          {option[`${key.name}_name`]}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                  {key?.type === 'checkbox' && (
                                    <input
                                      id={key.name}
                                      type='checkbox'
                                      className='p-0_5'
                                      placeholder={key.name}
                                      onChange={() =>
                                        handleChange(key.name, !currentUser[key.name])
                                      }
                                      defaultChecked={
                                        action === 'edit' ? currentUser[key.name] : ''
                                      }
                                    />
                                  )}
                                </>
                              ) : (
                                <span className=''>
                                  {key?.type === 'checkbox'
                                    ? currentUser[key.name]
                                      ? 'Active'
                                      : 'No active'
                                    : currentUser[key.name]}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
