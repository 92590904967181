import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import { BsCardText, BsArrowDownUp, BsGearFill, BsLayoutTextWindowReverse } from 'react-icons/bs';
import {
  changeOrder,
  changePagination,
  changeWaterCourse,
  getDeviceWaterCourse,
  updateDeviceWaterCourse,
} from '../../redux/slices/devicesSlice';
import MUIModal from '../../components/MUIModal/MUIModal';
import MUIInput from '../../components/MUIInput/MUIInput';
import MUIPagination from '../../components/MUIPagination/MUIPagination';

function DevicesTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    devices: { count, order, pagination, devices, columns, waterCourse },
  } = useSelector(store => store);
  const [openModal, setOpenModal] = useState({ statue: false, id: null });
  const [openChangeValueModal, setChangeValueModal] = useState({ status: false, id: null });

  const handleOpenModal = id => setOpenModal({ status: true, id });
  const handleCloseModal = () => setOpenModal({ status: false, id: null });

  const handleModalAction = (status, id) => {
    setChangeValueModal({ status, id });
    status && dispatch(getDeviceWaterCourse(id));
  };

  const handleChangePagination = (name, value) => {
    dispatch(changePagination({ name, value }));
  };

  const handleChangeWaterCourse = value => {
    dispatch(changeWaterCourse(value));
  };

  const handleSaveWaterCourse = (id, data) => {
    dispatch(updateDeviceWaterCourse({ id, water_course: data }));
    handleModalAction(false, '');
  };

  const handleOrder = key => {
    dispatch(changeOrder(key));
  };

  const checkedBg = device => {
    if (!device?.is_registred) return 'bg-blue color-white';
    if (device?.last_online) return 'bg-red color-white';
    return 'bg-white';
  };

  return (
    <>
      <MUIModal open={openModal?.status} onClose={handleCloseModal}>
        <div className='flex flex-col items-start bg-white  border-radius-0_3r p-1_5'>
          <span
            onClick={() => navigate(`/admin/device/${openModal?.id}`)}
            className='w-full border-circle hover-bg-gray-light py-0_5 px-2 fs-xl flex items-center  gap-1 transition-0_3s cursor-pointer'
          >
            <BsCardText /> <span className='fs-md'>Device</span>
          </span>
          <span
            onClick={() => navigate(`/admin/device/edit/${openModal?.id}`)}
            className='w-full border-circle hover-bg-gray-light py-0_5 px-2 fs-xl flex items-center  gap-1 transition-0_3s cursor-pointer'
          >
            <BsGearFill /> <span className='fs-md'>Settings</span>
          </span>
          <span
            onClick={() => navigate(`/admin/device/value-table/${openModal?.id}`)}
            className='w-full border-circle hover-bg-gray-light py-0_5 px-2 fs-xl flex items-center  gap-1 transition-0_3s cursor-pointer'
          >
            <BsLayoutTextWindowReverse /> <span className='fs-md'>Value table</span>
          </span>
          <span
            onClick={() => handleModalAction(true, openModal?.id)}
            className='w-full border-circle hover-bg-gray-light py-0_5 px-2 fs-xl flex items-center  gap-1 transition-0_3s cursor-pointer'
          >
            <BsArrowDownUp /> <span className='fs-md'>Water course</span>
          </span>
        </div>
      </MUIModal>

      <MUIModal open={openChangeValueModal?.status} onClose={() => handleModalAction(false, '')}>
        <div className='bg-white w-25r border-radius-0_3r p-1_5 md:w-90'>
          <p className='fs-lg mb-0_5'>Изменить значение Русло</p>
          {/* <p>Туркистон канали</p> */}
          <MUIInput
            fullWidth
            type='number'
            variant='standard'
            label='Туркистон канали'
            value={waterCourse || ''}
            onChange={e => handleChangeWaterCourse(parseFloat(e.target.value))}
          />
          <div className='flex items-center justify-center gap-1 mt-2'>
            <button
              type='button'
              className='px-1 py-0_5 fs-xm border-radius-0_3r border-none color-white bg-blue'
              onClick={() => handleModalAction(false, '')}
            >
              Отменить
            </button>
            <button
              type='button'
              onClick={() => handleSaveWaterCourse(openChangeValueModal?.id, waterCourse)}
              className='px-1 py-0_5 fs-xm border-radius-0_3r border-none color-white bg-green'
            >
              Сохранить
            </button>
          </div>
        </div>
      </MUIModal>

      <div className='h-max-70vh overflowX-scroll border-1px border-gray-light'>
        <table className='w-min-full border-collapse border-1px border-gray-light'>
          <thead className='position-fixed top-0 bg-white'>
            <tr className='color-gray  fs-md'>
              <th
                onClick={() => handleOrder('id')}
                className='cursor-pointer flex items-center gap-0_5 outline-1px outline-gray-light p-1'
              >
                <span>№</span>
                <span>
                  {order?.key === 'id' &&
                    (order?.status ? <FaSortAmountDown /> : <FaSortAmountDownAlt />)}
                </span>
              </th>
              {columns?.map(
                key =>
                  key?.show && (
                    <th
                      key={key.id}
                      onClick={() => handleOrder(key.name)}
                      className='cursor-pointer outline-1px outline-gray-light p-1 text-nowrap'
                    >
                      <span>{key.label || key.name}</span>
                      <span>
                        {order?.key === key?.name &&
                          (order?.status ? <FaSortAmountDown /> : <FaSortAmountDownAlt />)}
                      </span>
                    </th>
                  ),
              )}
            </tr>
          </thead>
          <tbody className='color-gray'>
            {devices?.map((device, idx) => (
              <tr key={device?.id} className='fs-md hover-bg-light cursor-pointer'>
                <td
                  className={`text-nowrap text-center border-1px border-gray-light p-1 ${checkedBg(
                    device,
                  )}`}
                >
                  {order?.key === 'id' && !order?.status
                    ? idx + 1 + pagination.pageSize * (pagination.pageNumber - 1)
                    : Math.abs(idx + pagination.pageSize * (pagination.pageNumber - 1) - count)}
                </td>
                {columns?.map(
                  key =>
                    key?.show && (
                      <td
                        key={key.id}
                        onClick={() => handleOpenModal(device?.id)}
                        className={`text-nowrap text-center border-1px border-gray-light p-1 ${checkedBg(
                          device,
                        )}`}
                        // onClick={() => navigate(`/admin/device/${device?.id}`)}
                      >
                        {device[key.name]}
                      </td>
                    ),
                )}
                {/* <td className='text-center border-1px border-gray-light px-1'>
                  <span
                    className='border-circle hover-bg-gray-light p-0_6 fs-xl flex items-center justify-center transition-0_3s'
                    onClick={() => navigate(`/admin/device/edit/${device?.id}`)}
                  >
                    <BsGearFill />
                  </span>
                </td>
                <td className='text-center border-1px border-gray-light px-1'>
                  <span
                    className='border-circle hover-bg-gray-light p-0_6 fs-xl flex items-center justify-center transition-0_3s'
                    onClick={() => navigate(`/admin/device/value-table/${device?.id}`)}
                  >
                    <BsLayoutTextWindowReverse />
                  </span>
                </td>
                <td className='text-center border-1px border-gray-light px-1'>
                  <span
                    className='border-circle hover-bg-gray-light p-0_6 fs-xl flex items-center justify-center transition-0_3s'
                    onClick={() => handleModalAction(true, device?.id)}
                  >
                    <BsArrowDownUp />
                  </span>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <MUIPagination
        count={count}
        pageSize={pagination?.pageSize}
        pageNumber={pagination?.pageNumber}
        onChangePageSize={pageSize => handleChangePagination('pageSize', pageSize)}
        onChangePageNumber={pageNumber => handleChangePagination('pageNumber', pageNumber)}
      />
    </>
  );
}

export default DevicesTable;
