import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

export const options = {
  responsive: true,
  scale: { pointLabel: false },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   tension: 5,
//   datasets: [
//     {
//       fill: true,
//       label: 'Dataset 2',
//       data: [12, 34, 67, 8, 9, 96, 45, 3, 1],
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//       tension: 0.4,
//     },
//     {
//       fill: true,
//       label: 'Dataset 3',
//       data: [12, 34, 67, 8, 9, 96, 45, 3, 1],
//       borderColor: 'rgb(53, 162, 2)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//       tension: 0.4,
//     },
//   ],
// };

function AreaChart({ labels, datasets }) {
  const data = {
    labels,
    datasets,
    tension: 1,
  };

  return <Line options={options} data={data} />;
}

AreaChart.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
};

export default AreaChart;
