import axios from 'axios';
import { getBaseURL } from './getBaseUrl';
import { memoizedRefreshToken } from './refreshToken';

axios.defaults.baseURL = getBaseURL();

axios.interceptors.request.use(
  async config => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo?.access) {
      config.headers = {
        ...config.headers,
        Authorization: `Token ${userInfo?.access}`,
      };
    }
    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => response,
  async error => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.access) {
        config.headers = {
          ...config.headers,
          authorization: `Token ${result?.access}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  },
);

export const axiosPrivate = axios;
