import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function MUISelect({
  options,
  label,
  optionParam = { value: 'value', label: 'label' },
  ...params
}) {
  return (
    <FormControl>
      <InputLabel id='demo-simple-select-autowidth-label'>{label}</InputLabel>
      <Select {...params} label={label}>
        {options?.map(option => (
          <MenuItem key={option?.id} value={option?.[optionParam?.value]}>
            {option?.[optionParam?.label]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MUISelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  optionParam: PropTypes.object,
};

export default MUISelect;
