import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

function MUIAutocomplete({ label, placeholder, ...param }) {
  return (
    <Autocomplete
      {...param}
      multiple
      id='tags-outlined'
      filterSelectedOptions
      renderInput={params => <TextField {...params} label={label} placeholder={placeholder} />}
    />
  );
}

MUIAutocomplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default MUIAutocomplete;
