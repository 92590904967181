import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { BsBoxArrowRight } from 'react-icons/bs';
import { TOPBAR_MENU } from '../../assets/constants/constants';
import { MUIMenu, MUIMenuItem } from '../../components/MUIMenu/MUIMenu';
import { openAndCloseSwiper } from '../../redux/slices/sidebarSlice';
import { logout } from '../../redux/slices/authSlice';

function Topbar() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const {
    auth: { userInfo },
    sidebar: { swiperStatus },
  } = useSelector(store => store);
  const [time, setTime] = useState(new Date().toLocaleString());
  const [menuOpen, setMenuOpen] = useState(null);

  setInterval(() => {
    setTime(new Date().toLocaleString());
  }, 1000);

  const handleOpenAndCloseSwiper = () => {
    dispatch(openAndCloseSwiper(!swiperStatus));
  };

  // const handleClick = pathname => {
  //   navigate(pathname);
  //   setMenuOpen(null);
  // };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <MUIMenu open={menuOpen ? true : false} anchorEl={menuOpen} onClose={() => setMenuOpen(null)}>
        {TOPBAR_MENU.map(menu => (
          <MUIMenuItem key={menu.name}>
            <div className='flex items-center gap-1'>
              <p className='fs-xm flex items-center color-gray'>{menu.icon}</p>
              <p className='color-dark fs-md'>{menu.label}</p>
            </div>
          </MUIMenuItem>
        ))}
        <hr className='border-1px my-0_5 border-light' />
        <MUIMenuItem onClick={handleLogout}>
          <div className='flex items-center gap-1 color-gray'>
            <p className='fs-xm flex items-center color-gray'>
              <BsBoxArrowRight />
            </p>
            <p className='color-dark fs-md'>Выйти</p>
          </div>
        </MUIMenuItem>
      </MUIMenu>
      <div className='flex justify-between items-center box-shadow-bottom pl-1 pr-0_5 py-0_5'>
        <span
          onClick={handleOpenAndCloseSwiper}
          className={`hamburger hamburger--elastic  ${
            swiperStatus ? 'is-active' : ''
          } hidden md:block`}
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner fs-xm' />
          </span>
        </span>
        <h1 className='color-gray fw-500 fs-2xl md:hidden'>{time}</h1>
        <div className='border-left-1px border-gray-light px-1'>
          <button
            type='button'
            className='flex items-center gap-0_5 px-1 py-0_5 bg-none border-none color-gray'
            onClick={e => setMenuOpen(e.currentTarget)}
          >
            {userInfo?.username}
            <Avatar
              sx={{ width: '2rem', height: '2rem' }}
              src='https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png'
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default Topbar;
