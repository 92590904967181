import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import MUILinerProgress from '../../components/MUILinerProgress/MUILinerProgress';
import Input from '../../components/Input/Input';
import { login } from '../../redux/slices/authSlice';

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    auth: { isLoading, userInfo },
  } = useSelector(store => store);
  const [user, setUser] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState({
    username: '',
    password: '',
  });

  const handleChangeUser = (name, value) => {
    setError({ ...error, [name]: '' });
    setUser({ ...user, [name]: value });
  };

  const handleChangeError = (name, value) => {
    setError({ ...error, [name]: value });
  };

  const handleSave = async userData => {
    if (!userData?.username && !userData?.password)
      setError({ username: 'Username required', password: 'Password required' });
    else if (!userData?.username) handleChangeError('username', 'Username required');
    else if (!userData?.password) handleChangeError('password', 'Password required');
    else if (userData?.username && userData.password) {
      await dispatch(login(userData));
    }
  };

  useEffect(() => {
    userInfo?.access && navigate('/admin');
  }, [userInfo]);

  return (
    <div className='h-100vh flex flex-col justify-center items-center position-relative'>
      {isLoading && <MUILinerProgress />}

      <div className='flex flex-col w-30r p-4 box-shadow-bottom border-radius-0_5r md:w-90 md:p-2'>
        <h4 className='my-2 justify-center flex fs-xl color-gray'>Добро пожаловать!</h4>
        <Input
          id='login'
          type='text'
          placeholder='Введите имя пользователя...'
          errorMessage={error.username}
          className='border-radius-0 border-1px border-gray-light outline-none mt-1 p-1 color-gray'
          onChange={e => handleChangeUser('username', e.target.value)}
        />
        <Input
          id='password'
          type='password'
          placeholder='Пароль'
          errorMessage={error.password}
          className='border-radius-0 border-1px border-gray-light outline-none mt-1 p-1 color-gray'
          onChange={e => handleChangeUser('password', e.target.value)}
        />
        <label className='flex gap-1 items-center fs-md mx-1 my-1_5' htmlFor='check'>
          <input type='checkbox' id='check' />
          <p className='color-gray fw-100'>Запомни меня</p>
        </label>
        <button
          className='py-1 color-white bg-blue border-none'
          type='submit'
          onClick={() => handleSave(user)}
        >
          Авторизоваться
        </button>
        <hr className='my-1 border-1px border-gray-light' />
        <Link to='/' className='fs-md text-decoration-none text-center mt-1'>
          Забыл пароль?
        </Link>
        <Link to='/' className='fs-md text-decoration-none text-center mt-1'>
          Создать аккаунт!
        </Link>
      </div>
    </div>
  );
}

export default LoginPage;
