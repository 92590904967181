import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../service/axiosPrivate';

const apiPath = 'devices/device-map/';

const initialState = {
  isLoading: false,
  maps: [],
};

export const getMaps = createAsyncThunk('map/getMaps', async () => {
  const response = await axiosPrivate.get(`${apiPath}`);
  return response.data;
});

export const mapSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMaps.pending, state => {
        state.isLoading = true;
      })
      .addCase(getMaps.fulfilled, (state, action) => {
        state.maps = action.payload;

        state.isLoading = false;
      });
  },
});

const { reducer, actions } = mapSlice;

export const { openAndCloseSidebar, openAndCloseSwiper } = actions;

export default reducer;
