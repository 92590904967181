import { useEffect } from 'react';
import { BsDiagram2, BsWifi } from 'react-icons/bs';
import { FaCarBattery, FaDatabase } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDeviceValues, updateDeviceValue } from '../../redux/slices/deviceValuesSlice';
import MUILinerProgress from '../../components/MUILinerProgress/MUILinerProgress';
import DeviceValueTable from './DeviceValueTable';

function DeviceValuePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    values: { sn, imei, batarey_voltage, created_at, table_value, isLoading },
  } = useSelector(store => store);

  const param = pathname.split('/')[4];

  const handleSave = (id, data) => {
    dispatch(updateDeviceValue({ id, data }));
  };

  useEffect(() => {
    dispatch(getDeviceValues(param));
  }, []);

  return (
    <div className='position-relative'>
      {isLoading && <MUILinerProgress />}
      <div className='mx-1 my-1_5'>
        <div className='flex justify-between fs-md mt-0_5 mb-1'>
          <button
            type='button'
            className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
            onClick={() => navigate(-1)}
          >
            Назад
          </button>
          {/* <button
          type='button'
          className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
          to='/'
        >
          Создать отчет
        </button> */}
        </div>
        <div className='grid justify-between gap-col-1 gap-row-2_5 w-full grid-temp-col-1-49 md:grid-temp-col-1-100'>
          <div className='px-1 flex justify-between items-center border-left-4px border-radius-0_3r border-blue py-1_5 box-shadow-0-0-1-0_5'>
            <div>
              <h6 className='fs-xs color-blue'>СЕРИЙНЫЙ НОМЕР:</h6>
              <h6 className='fs-xl fw-500 color-dark mt-0_2'>{sn}</h6>
            </div>

            <h6 className='fs-2xl color-gray-light'>
              <BsDiagram2 />
            </h6>
          </div>
          <div className='px-1 flex justify-between items-center  border-left-4px border-radius-0_3r border-green py-1_5 box-shadow-0-0-1-0_5'>
            <div>
              <h6 className='fs-xs color-green'>IMEI</h6>
              <h6 className='fs-xl fw-500 color-dark mt-0_2'>{imei}</h6>
            </div>

            <h6 className='fs-2xl color-gray-light'>
              <BsWifi />
            </h6>
          </div>
          <div className='px-1 flex justify-between items-center  border-left-4px border-radius-0_3r border-cyan py-1_5 box-shadow-0-0-1-0_5'>
            <div>
              <h6 className='fs-xs color-cyan'>УРОВЕНЬ ЗАРЯДА БАТАРЕИ</h6>
              <h6 className='fs-xl fw-500 color-dark mt-0_2'>{batarey_voltage}</h6>
            </div>
            <div>
              <h6 className='fs-2xl color-gray-light'>
                <FaCarBattery />
              </h6>
            </div>
          </div>
          <div className='px-1 flex justify-between items-center border-left-4px border-radius-0_3r border-orange py-1_5 box-shadow-0-0-1-0_5'>
            <div>
              <h6 className='fs-xs color-orange'>ДАТА СОЗДАНИЯ</h6>
              <h6 className='fs-xl fw-500 color-dark mt-0_2'>{created_at}</h6>
            </div>

            <h6 className='fs-2xl color-gray-light'>
              <FaDatabase />
            </h6>
          </div>
        </div>
        <div className='border-1px border-radius-0_3r border-gray-light box-shadow overflow-hidden mt-2_5'>
          <h4 className='color-blue fs-md p-1 fs-xm border-bottom-1px border-gray-light fw-500'>
            Настройки прибора
          </h4>
          <div className='px-1 py-1_5 bg-white'>
            <DeviceValueTable />
          </div>
          <div className='p-1 border-top-1px border-gray-light fw-500 flex items-center justify-end gap-1'>
            <button
              type='button'
              onClick={() => navigate(-1)}
              className='px-1 py-0_5 fs-xm border-radius-0_3r border-none color-white bg-blue'
            >
              Отменить
            </button>
            <button
              type='button'
              onClick={() => handleSave(param, table_value)}
              className='px-1 py-0_5 fs-xm border-radius-0_3r border-none color-white bg-green'
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceValuePage;
