import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BsDiagram2, BsThreeDotsVertical, BsWifi } from 'react-icons/bs';
import { FaCarBattery, FaDatabase } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AreaChart from '../../components/Charts/AreaChart';
import MUILinerProgress from '../../components/MUILinerProgress/MUILinerProgress';
import { MUIMenu, MUIMenuItem } from '../../components/MUIMenu/MUIMenu';
import { changeSearch, getDeviceIndicators } from '../../redux/slices/deviceIndicators';
import { getDeviceById } from '../../redux/slices/devicesSlice';
import { getGraphics } from '../../redux/slices/graphicsSlice';
import ResultsTable from './ResultsTable';

function Device() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    devices: { currentDevice },
    indicators: { isLoading, search, pagination },
    graphics: { label, water_level, water_course, batarey_voltage, water_consumption },
  } = useSelector(store => store);
  const [mapMenu, setMapMenu] = useState(null);
  const [chartMenu, setChartMenu] = useState(null);

  const param = pathname.split('/')[3];

  const deviceIndicatorsGraphic = useMemo(
    () => (
      <AreaChart
        labels={label}
        datasets={[
          {
            data: water_level,
            fill: true,
            border: 'blue',
            label: 'water_level',
            backgroundColor: 'blue',
          },
          {
            data: water_course,
            fill: true,
            border: 'green',
            label: 'water_level',
            backgroundColor: 'green',
          },
          {
            data: batarey_voltage,
            fill: true,
            border: 'red',
            label: 'water_level',
            backgroundColor: 'red',
          },
          {
            data: water_consumption,
            fill: true,
            border: 'gray',
            label: 'water_level',
            backgroundColor: 'gray',
          },
        ]}
      />
    ),
    [label, water_level, water_course, batarey_voltage, water_consumption],
  );

  const debouncedSearch = useCallback(
    debounce(text => dispatch(changeSearch(text)), 512),
    [search],
  );

  const handleChangeSearch = value => {
    debouncedSearch(value);
  };

  useEffect(() => {
    dispatch(getDeviceById(param));
    dispatch(getGraphics({ id: param }));
  }, []);

  useEffect(() => {
    dispatch(getDeviceIndicators({ id: param, pagination, search }));
  }, [pagination, param, search]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <>
      <div className='position-relative'>
        {isLoading && <MUILinerProgress />}
        <div className='mx-1 my-1_5 flex flex-col'>
          <div className='flex justify-between fs-md mt-0_5 mb-1'>
            <button
              type='button'
              className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
              onClick={() => navigate(-1)}
            >
              Назад
            </button>
            <button
              type='button'
              className='border-none outline-none fs-md color-white bg-blue px-1 py-0_5 border-radius-0_3r'
              to='/'
            >
              Создать отчет
            </button>
          </div>

          <div className='grid justify-between gap-col-1 gap-row-2_5 w-full grid-temp-col-1-49 md:grid-temp-col-1-100'>
            <div className='px-1 flex justify-between items-center border-left-4px border-radius-0_3r border-blue py-1_5 box-shadow-0-0-1-0_5'>
              <div>
                <h6 className='fs-xs color-blue'>СЕРИЙНЫЙ НОМЕР:</h6>
                <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.sn}</h6>
              </div>

              <h6 className='fs-2xl color-gray-light'>
                <BsDiagram2 />
              </h6>
            </div>
            <div className='px-1 flex justify-between items-center  border-left-4px border-radius-0_3r border-green py-1_5 box-shadow-0-0-1-0_5'>
              <div>
                <h6 className='fs-xs color-green'>IMEI</h6>
                <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.imei}</h6>
              </div>

              <h6 className='fs-2xl color-gray-light'>
                <BsWifi />
              </h6>
            </div>
            <div className='px-1 flex justify-between items-center  border-left-4px border-radius-0_3r border-cyan py-1_5 box-shadow-0-0-1-0_5'>
              <div>
                <h6 className='fs-xs color-cyan'>УРОВЕНЬ ЗАРЯДА БАТАРЕИ</h6>
                <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.batarey_voltage}</h6>
              </div>
              <div>
                <h6 className='fs-2xl color-gray-light'>
                  <FaCarBattery />
                </h6>
              </div>
            </div>
            <div className='px-1 flex justify-between items-center border-left-4px border-radius-0_3r border-orange py-1_5 box-shadow-0-0-1-0_5'>
              <div>
                <h6 className='fs-xs color-orange'>ДАТА СОЗДАНИЯ</h6>
                <h6 className='fs-xl fw-500 color-dark mt-0_2'>{currentDevice?.created_at}</h6>
              </div>

              <h6 className='fs-2xl color-gray-light'>
                <FaDatabase />
              </h6>
            </div>
          </div>
          <div className='border-1px border-radius-0_3r border-gray-light box-shadow overflow-hidden mt-2_5'>
            <h4 className='color-blue fs-md p-1 fs-xm'>Серийный номер: {currentDevice?.sn}</h4>
            <hr className='border-gray-light border-1px' />
            <div className='px-1 bg-white'>
              <div className='flex justify-end py-1 md:justify-center'>
                <input
                  type='text'
                  placeholder='Search'
                  onChange={e => handleChangeSearch(e.target.value)}
                  className='border-gray-light border-radius-0_3r p-0_5 border-1px md:w-full'
                />
              </div>
              <ResultsTable />
            </div>
          </div>

          <div className='flex justify-between gap-1 mt-1 md:flex-col'>
            <div className='border-1px border-gray-light w-50 border-box border-radius-0_3r overflow-hidden box-shadow md:w-full'>
              <div className='flex justify-between items-center p-1 color-blue bg-light'>
                <h2>Карта</h2>
                <button
                  type='submit'
                  onClick={e => setMapMenu(e.currentTarget)}
                  className='bg-none border-none outline-none color-gray-light fs-xl active-outline-3px border-radius-0_3r transition-0_3s flex justify-center items-center'
                >
                  <BsThreeDotsVertical />
                </button>
                <MUIMenu
                  anchorEl={mapMenu}
                  open={mapMenu ? true : false}
                  onClose={() => setMapMenu(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MUIMenuItem>
                    <span className='fs-md'>Action</span>
                  </MUIMenuItem>
                  <MUIMenuItem>
                    <span className='fs-md'>Another action</span>
                  </MUIMenuItem>
                </MUIMenu>
              </div>
              <hr className='border-gray-light border-1px' />
              <div className='w-full h-full'>
                <YMaps>
                  <Map
                    width='100%'
                    height='100%'
                    defaultState={{
                      center: [currentDevice?.latitude, currentDevice?.longitude],
                      zoom: 12,
                    }}
                  >
                    <Placemark geometry={[currentDevice?.latitude, currentDevice?.longitude]} />
                  </Map>
                </YMaps>
              </div>
            </div>

            <div className='border-1px border-gray-light w-50 border-box border-radius-0_3r overflow-hidden box-shadow md:w-full'>
              <div className='flex justify-between items-center p-1 color-blue bg-light'>
                <h2>График</h2>
                <button
                  type='submit'
                  onClick={e => setChartMenu(e.currentTarget)}
                  className='bg-none border-none outline-none color-gray-light fs-xl active-outline-3px border-radius-0_3r transition-0_3s flex justify-center items-center'
                >
                  <BsThreeDotsVertical />
                </button>
                <MUIMenu
                  anchorEl={chartMenu}
                  open={chartMenu ? true : false}
                  onClose={() => setChartMenu(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MUIMenuItem>
                    <span className='fs-md'>Day</span>
                  </MUIMenuItem>
                  <MUIMenuItem>
                    <span className='fs-md'>Week</span>
                  </MUIMenuItem>
                  <MUIMenuItem>
                    <span className='fs-md'>Month</span>
                  </MUIMenuItem>
                </MUIMenu>
              </div>
              <hr className='border-gray-light border-1px' />
              <div className='p-1'>{deviceIndicatorsGraphic}</div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='w-full py-2 bg-light box-shadow-top text-center'>
        <h3 className='color-gray fw-100'>Copyright © Maxwell.uz 2021</h3>
      </div> */}
    </>
  );
}

export default Device;
