import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../service/axiosPrivate';

const apiPath = 'devices/indicators-graph/';

const initialState = {
  isLoading: false,
  label: [],
  water_level: [],
  water_course: [],
  batarey_voltage: [],
  water_consumption: [],
};

export const getGraphics = createAsyncThunk('devices/getIndicatorsGraphics', async ({ id }) => {
  const response = await axiosPrivate.get(`${apiPath}?device=${id}`);
  return response.data;
});

export const graphicsSlice = createSlice({
  name: 'indicatorsGraphics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGraphics.pending, state => {
        state.isLoading = true;
      })
      .addCase(getGraphics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.label = action.payload.time_of_data;
        state.water_level = action.payload.water_level;
        state.water_course = action.payload.water_course;
        state.batarey_voltage = action.payload.batarey_voltage;
        state.water_consumption = action.payload.water_consumption;
      });
  },
});

const { reducer, actions } = graphicsSlice;

export const { openAndCloseSidebar, openAndCloseSwiper } = actions;

export default reducer;
