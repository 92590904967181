import { useState } from 'react';
import PropTypes from 'prop-types';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

function Input({ id = '', type = 'text', errorMessage, margin, className, ...params }) {
  const [hidden, setHidden] = useState(false);

  return (
    <>
      <label htmlFor={id} className={`flex items-center position-relative ${className}`}>
        <input
          id={id}
          type={type === 'password' ? (hidden ? 'text' : 'password') : type}
          className='w-full border-none outline-none placeholder-gray-light'
          {...params}
        />
        {type === 'password' && (
          <button
            type='button'
            onClick={() => setHidden(!hidden)}
            className='fs-2xl position-absolute right-0_5r bg-none border-none outline-none'
          >
            {hidden ? <BsEye fontSize='1.5rem' /> : <BsEyeSlash fontSize='1.5rem' />}
          </button>
        )}
      </label>
      <p className='color-red ml-1 mt-0_2'>{errorMessage || ''}</p>
    </>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Input;
