import { useDispatch, useSelector } from 'react-redux';
import MUIPagination from '../../components/MUIPagination/MUIPagination';
import { changePagination } from '../../redux/slices/deviceIndicators';
import { DEVICE_RESULTS_TABLE_KEYS } from '../../assets/constants/constants';

function ResultsTable() {
  const dispatch = useDispatch();
  const {
    indicators: { count, pagination, indicators },
  } = useSelector(store => store);

  const handleChangePagination = (name, value) => {
    dispatch(changePagination({ name, value }));
  };

  return (
    <>
      <div className='h-max-70vh overflowX-scroll border-1px border-gray-light'>
        <table className='w-min-full border-collapse border-1px border-gray-light color-gray'>
          <thead>
            <tr className='fs-md'>
              <th className='border-1px border-gray-light p-1'>№</th>
              {DEVICE_RESULTS_TABLE_KEYS.map(key => (
                <th key={key.id} className='border-1px border-gray-light p-1'>
                  {key.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {indicators?.map((indicator, idx) => (
              <tr key={indicator?.id} className='fs-md hover-bg-light cursor-pointer'>
                <td className='text-nowrap text-center border-1px border-gray-light p-1 '>
                  {idx + 1 + pagination.pageSize * (pagination.pageNumber - 1)}
                </td>
                {DEVICE_RESULTS_TABLE_KEYS.map(key => (
                  <td
                    key={key.id}
                    className='text-nowrap text-center border-1px border-gray-light p-1 '
                  >
                    {indicator[key.name]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <MUIPagination
        count={count}
        pageSize={pagination?.pageSize}
        pageNumber={pagination?.pageNumber}
        onChangePageSize={pageSize => handleChangePagination('pageSize', pageSize)}
        onChangePageNumber={pageNumber => handleChangePagination('pageNumber', pageNumber)}
      />
    </>
  );
}

export default ResultsTable;
