import { configureStore } from '@reduxjs/toolkit';
import indicators from './slices/deviceIndicators';
import values from './slices/deviceValuesSlice';
import graphics from './slices/graphicsSlice';
import sidebar from './slices/sidebarSlice';
import devices from './slices/devicesSlice';
import selects from './slices/selectsSlice';
import users from './slices/usersSlice';
import auth from './slices/authSlice';
import maps from './slices/mapsSlice';

export const store = configureStore({
  reducer: {
    auth,
    maps,
    users,
    values,
    devices,
    sidebar,
    selects,
    graphics,
    indicators,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(),
  devTools: process.env.NODE_ENV !== 'production',
});
