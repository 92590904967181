import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';

export function MUIMenu({ children, ...param }) {
  return (
    <Menu
      {...param}
      id='basic-menu'
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {children}
    </Menu>
  );
}

export function MUIMenuItem({ children, ...params }) {
  return <MenuItem {...params}>{children}</MenuItem>;
}

MUIMenu.propTypes = {
  children: PropTypes.node,
};

MUIMenuItem.propTypes = {
  children: PropTypes.node,
};
